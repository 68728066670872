$auditionHeight: 200px;

@import url('https://fonts.googleapis.com/css2?family=Khand:wght@500;700&display=swap');

/* RAMÓWKA - NAWIGACJA */
#master-schedule-tabs {
  transition: 0.6s;
  margin: 20px auto !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff !important;
  box-shadow: 3px 2px 5px 1px #ccc;
}
#master-schedule-controls-wrapper {
  display: none;
}
.master-schedule-tabs-day {
  transition: 0.6s;
  border: none !important;
  background-color: #fff !important;
  padding: 18px;
  margin: 0 !important;
}
.master-schedule-tab-bottom {
  transition: 0.6s;
  border-bottom: 2px solid $color-primary;
  left: 0;
}
.master-schedule-tabs-day-name {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
  color: $color-primary;
  background-color: #fff;
}
/* RAMÓWKA - TABY */
ul.master-schedule-tabs-panel.active-day-panel {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
  background-color: transparent !important;
  border: none !important;
}
.master-schedule-tabs-show {
  width: 33%;
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  height: $auditionHeight;
  position: relative;
  width: 100%;
  margin-right: 15px !important;
  margin-left: 15px !important;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  box-shadow: 3px 2px 5px 1px #ccc;
  .show-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    max-height: 100%;
    a {
      width: 100%;
      height: 100%;
      max-height: 100%;
      display: inline-block;
    }
    img {
      width: 100%;
      height: $auditionHeight;
      object-fit: cover;
    }
  }
  .show-info {
    background: transparent;
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  .show-title {
    color: #fff;
    font-weight: 700;
    font-family: 'Khand', sans-serif;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 3px;
    position: relative;
    a {
      color: inherit !important;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: #fff6;
    }
  }
  .show-time {
    color: #fff;
  }
  .show-genres {
    margin: 10px 0;
    a {
      color: #fff !important;
    }
  }
}
