.prezenter-audycja {
  padding: 15px;
  margin: 30px 0;

  @media screen and (min-width: 510px) {
    padding: 30px;
  }
  position: relative;
  &__mobile-only {
    display: block;
    @media screen and (min-width: 510px) {
      display: none;
    }
  }
  &__content {
    background: rgba($color: #000000, $alpha: 0.75);
    color: #f6f6f6;
    padding: 15px;
    @media screen and (min-width: 510px) {
      padding: 30px;
    }
    z-index: 12;
    position: relative;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 11;
  }

  &__title {
    font-weight: 700 !important;
    font-size: 40px !important;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff !important;
    a {
      color: inherit;
      transition: 0.2s;
      &:hover {
        color: $color-tertiary;
      }
    }
  }
  &__subtitle {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 5px !important;
    font-weight: 300 !important;
    color: #bbb !important;
  }
  &__description {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__icon {
    margin-right: 10px;
  }
  &__genres {
    font-size: 13px;
    text-transform: uppercase;
  }
  &__genre {
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
  }
  &__shifts {
  }
  &__shift {
    margin: 15px 0;
    &:last-child {
      margin: 15px 0 0;
    }
  }
  &__shift-day,
  &__shift-start,
  &__shift-end {
    margin-right: 10px;
  }
  &__shift-day {
  }
  &__shift-start {
  }
  &__shift-end {
  }
}
