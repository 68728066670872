@import 'base/buttons';
@import 'base/colors';
@import 'base/mixins';

@import 'modules/radio-schedule';
@import 'modules/home-play-button';
@import 'modules/fusion-post-wrapper';
@import 'modules/fusion-nav-logo';
@import 'modules/fusion-nav-items';
@import 'modules/ce-image_card_01';
@import 'modules/prezenter-audycje';
@import 'modules/prezenter-audycja';
@import 'modules/shoutbox-icon';
@import 'modules/dark-bg-posts';

.radiospin-button {
  a {
    background: #fff !important;
    border-color: #b06cf4 !important;
    border-width: 2px;
    border-radius: 10vh !important;
    color: #b06cf4 !important;
    &:hover {
      border-color: #393faa !important;
      color: #fff !important;
      background: #393faa !important;
      background-image: linear-gradient(to top, #3e2a66, #393faa) !important;
    }
  }
}

.shoutbox {
  position: fixed;
  z-index: 99999;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  transition: 0.5s;
  .shoutboxBtn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-80%, -50%);
    @include mediumDown {
      top: auto;
      bottom: 0;
      transform: translate(-90%, 15%);
      // bottom: 1%;
    }
  }
  &--is-active {
    transform: translate(0, -50%);
    @include mediumDown {
      transform: translate(15%, -50%);
      // bottom: 1%;
    }
  }
}
