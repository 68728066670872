@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Khand:wght@500;700&display=swap");
.btn--large {
  font-size: 1.4rem !important;
  padding: 30px !important; }

/* RAMÓWKA - NAWIGACJA */
#master-schedule-tabs {
  transition: 0.6s;
  margin: 20px auto !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff !important;
  box-shadow: 3px 2px 5px 1px #ccc; }

#master-schedule-controls-wrapper {
  display: none; }

.master-schedule-tabs-day {
  transition: 0.6s;
  border: none !important;
  background-color: #fff !important;
  padding: 18px;
  margin: 0 !important; }

.master-schedule-tab-bottom {
  transition: 0.6s;
  border-bottom: 2px solid #3e2a66;
  left: 0; }

.master-schedule-tabs-day-name {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
  color: #3e2a66;
  background-color: #fff; }

/* RAMÓWKA - TABY */
ul.master-schedule-tabs-panel.active-day-panel {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
  background-color: transparent !important;
  border: none !important; }

.master-schedule-tabs-show {
  width: 33%;
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  height: 200px;
  position: relative;
  width: 100%;
  margin-right: 15px !important;
  margin-left: 15px !important;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  box-shadow: 3px 2px 5px 1px #ccc; }
  .master-schedule-tabs-show .show-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    max-height: 100%; }
    .master-schedule-tabs-show .show-image a {
      width: 100%;
      height: 100%;
      max-height: 100%;
      display: inline-block; }
    .master-schedule-tabs-show .show-image img {
      width: 100%;
      height: 200px;
      object-fit: cover; }
  .master-schedule-tabs-show .show-info {
    background: transparent;
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    padding: 30px; }
  .master-schedule-tabs-show .show-title {
    color: #fff;
    font-weight: 700;
    font-family: 'Khand', sans-serif;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 3px;
    position: relative; }
    .master-schedule-tabs-show .show-title a {
      color: inherit !important; }
    .master-schedule-tabs-show .show-title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      background: #fff6; }
  .master-schedule-tabs-show .show-time {
    color: #fff; }
  .master-schedule-tabs-show .show-genres {
    margin: 10px 0; }
    .master-schedule-tabs-show .show-genres a {
      color: #fff !important; }

.home-play-button {
  text-align: center; }
  .home-play-button a {
    display: inline-block; }
  .home-play-button img {
    height: 160px;
    transition: 0.1s;
    opacity: 0.8; }
    .home-play-button img:hover {
      opacity: 1; }

.home-posts {
  margin-bottom: 30px; }
  .home-posts .fusion-post-wrapper {
    border-radius: 10px;
    background-color: #fdfdfd !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: white;
    text-decoration: none;
    color: #444; }
    .home-posts .fusion-post-wrapper .fusion-image-wrapper {
      border-radius: 10px 10px 0 0; }

.blog-shortcode-post-title {
  margin-bottom: 0 !important;
  line-height: 1.2 !important; }
  .blog-shortcode-post-title a {
    text-transform: uppercase;
    letter-spacing: 1px; }

.fusion-single-line-meta {
  position: relative;
  padding-top: 2px;
  padding-bottom: 20px; }
  .fusion-single-line-meta a {
    color: #bbb; }
  .fusion-single-line-meta:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #ccc; }

.fusion-standard-logo {
  max-height: 35px; }
  @media screen and (min-width: 700px) {
    .fusion-standard-logo {
      max-height: 50px; } }
  @media screen and (min-width: 1401px) {
    .fusion-standard-logo {
      max-height: 80px; } }

.snip1566 {
  position: relative;
  display: inline-block;
  margin: 20px 20px 10px;
  max-width: 280px;
  width: 100%;
  color: #bbb;
  font-size: 16px;
  box-shadow: none !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.snip1566 *,
.snip1566:before,
.snip1566:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }

.snip1566:before,
.snip1566:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  border: 2px solid #bbb;
  border-color: transparent #bbb; }

.snip1566 img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
  border-radius: 50%;
  padding: 10px; }

.snip1566 figcaption {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 50%; }

.snip1566 i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 4em;
  z-index: 1; }

.snip1566 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; }

.snip1566:hover figcaption,
.snip1566.hover figcaption {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.snip1566:hover:before,
.snip1566.hover:before,
.snip1566:hover:after,
.snip1566.hover:after {
  border-width: 10px; }

.snip1566:hover:before,
.snip1566.hover:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.snip1566:hover:after,
.snip1566.hover:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.profile-card {
  text-align: center; }
  .profile-card__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .profile-card__col {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media screen and (min-width: 500px) {
      .profile-card__col {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media screen and (min-width: 700px) {
      .profile-card__col {
        flex: 0 0 33%;
        max-width: 33%; } }
  .profile-card__title {
    margin-top: 0;
    margin-bottom: 0;
    color: #fff !important;
    text-align: center;
    font-weight: 800 !important; }
  .profile-card__job {
    color: #fff !important;
    text-align: center;
    font-weight: 300 !important; }

.prezenter-audycje__title {
  margin-top: 40px;
  margin-bottom: 30px; }

.prezenter-audycja {
  padding: 15px;
  margin: 30px 0;
  position: relative; }
  @media screen and (min-width: 510px) {
    .prezenter-audycja {
      padding: 30px; } }
  .prezenter-audycja__mobile-only {
    display: block; }
    @media screen and (min-width: 510px) {
      .prezenter-audycja__mobile-only {
        display: none; } }
  .prezenter-audycja__content {
    background: rgba(0, 0, 0, 0.75);
    color: #f6f6f6;
    padding: 15px;
    z-index: 12;
    position: relative; }
    @media screen and (min-width: 510px) {
      .prezenter-audycja__content {
        padding: 30px; } }
  .prezenter-audycja > img {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 11; }
  .prezenter-audycja__title {
    font-weight: 700 !important;
    font-size: 40px !important;
    margin-top: 0;
    margin-bottom: 0;
    color: #fff !important; }
    .prezenter-audycja__title a {
      color: inherit;
      transition: 0.2s; }
      .prezenter-audycja__title a:hover {
        color: #b06cf4; }
  .prezenter-audycja__subtitle {
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 5px !important;
    font-weight: 300 !important;
    color: #bbb !important; }
  .prezenter-audycja__description {
    margin-top: 20px;
    margin-bottom: 20px; }
  .prezenter-audycja__icon {
    margin-right: 10px; }
  .prezenter-audycja__genres {
    font-size: 13px;
    text-transform: uppercase; }
  .prezenter-audycja__genre:after {
    content: ', '; }
  .prezenter-audycja__genre:last-child:after {
    content: ''; }
  .prezenter-audycja__shift {
    margin: 15px 0; }
    .prezenter-audycja__shift:last-child {
      margin: 15px 0 0; }
  .prezenter-audycja__shift-day, .prezenter-audycja__shift-start, .prezenter-audycja__shift-end {
    margin-right: 10px; }

.shoutbox-icon {
  cursor: pointer;
  position: relative;
  font-size: 40px;
  background-color: #262626;
  color: #ffffff;
  width: 75px;
  margin: 40px;
  height: 75px;
  line-height: 75px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1) 100%);
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.2), inset 0px -1px 1px rgba(0, 0, 0, 0.5), 1px 1px 4px rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  @media screen and (max-width: 718px) {
    .shoutbox-icon {
      font-size: 25px;
      color: #ffffff;
      margin: 25px;
      width: 50px;
      height: 50px;
      line-height: 50px; } }

.shoutbox-icon:before {
  z-index: 2;
  opacity: 0.75; }

.shoutbox-icon:after {
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  content: '';
  border-radius: 50%;
  z-index: -1;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.6); }

.shoutbox-icon:before,
.shoutbox-icon:after {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }

.shoutbox-icon:hover,
.shoutbox-icon.hover {
  text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.8);
  color: #ffffff; }

.shoutbox-icon:hover:after,
.shoutbox-icon.hover:after {
  opacity: 0; }

.shoutbox-icon:hover:before,
.shoutbox-icon.hover:before {
  opacity: 1; }

.shoutbox-icon:active {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  color: #ffffff; }

.shoutbox-icon {
  background-color: #f7b426; }

.shoutbox-icon:after {
  background-color: #f7b426; }

.dark-bg-posts .entry-title a {
  color: #e4cbfd !important; }

.dark-bg-posts .fusion-single-line-meta,
.dark-bg-posts .fusion-single-line-meta a,
.dark-bg-posts .fusion-post-content-container {
  color: #fff !important; }

.radiospin-button a {
  background: #fff !important;
  border-color: #b06cf4 !important;
  border-width: 2px;
  border-radius: 10vh !important;
  color: #b06cf4 !important; }
  .radiospin-button a:hover {
    border-color: #393faa !important;
    color: #fff !important;
    background: #393faa !important;
    background-image: linear-gradient(to top, #3e2a66, #393faa) !important; }

.shoutbox {
  position: fixed;
  z-index: 99999;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  transition: 0.5s; }
  .shoutbox .shoutboxBtn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-80%, -50%); }
    @media screen and (max-width: 718px) {
      .shoutbox .shoutboxBtn {
        top: auto;
        bottom: 0;
        transform: translate(-90%, 15%); } }
  .shoutbox--is-active {
    transform: translate(0, -50%); }
    @media screen and (max-width: 718px) {
      .shoutbox--is-active {
        transform: translate(15%, -50%); } }
