.shoutbox-icon {
  cursor: pointer;
  position: relative;
  font-size: 40px;
  background-color: #262626;
  color: #ffffff;
  width: 75px;
  margin: 40px;
  height: 75px;
  line-height: 75px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1) 100%);
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.2), inset 0px -1px 1px rgba(0, 0, 0, 0.5),
    1px 1px 4px rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 1;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  @include mediumDown {
    font-size: 25px;
    color: #ffffff;
    margin: 25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.shoutbox-icon:before {
  z-index: 2;
  opacity: 0.75;
}

.shoutbox-icon:after {
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000000;
  content: '';
  border-radius: 50%;
  z-index: -1;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.6);
}

.shoutbox-icon:before,
.shoutbox-icon:after {
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.shoutbox-icon:hover,
.shoutbox-icon.hover {
  text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.8);
  color: #ffffff;
}

.shoutbox-icon:hover:after,
.shoutbox-icon.hover:after {
  opacity: 0;
}

.shoutbox-icon:hover:before,
.shoutbox-icon.hover:before {
  opacity: 1;
}

.shoutbox-icon:active {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  color: #ffffff;
}

.shoutbox-icon {
  background-color: #f7b426;
}

.shoutbox-icon:after {
  background-color: #f7b426;
}
