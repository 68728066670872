.home-play-button {
  text-align: center;
  a {
    display: inline-block;
  }
  img {
    height: 160px;
    transition: 0.1s;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
