.home-posts {
  margin-bottom: 30px;
  .fusion-post-wrapper {
    border-radius: 10px;
    background-color: #fdfdfd !important;
    .fusion-image-wrapper {
      border-radius: 10px 10px 0 0;
    }
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: white;
    text-decoration: none;
    color: #444;
  }
}

.blog-shortcode-post-title {
  margin-bottom: 0 !important;
  line-height: 1.2 !important;
  a {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
.fusion-single-line-meta {
  position: relative;
  padding-top: 2px;
  padding-bottom: 20px;
  a {
    color: #bbb;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #ccc;
  }
}
