.dark-bg-posts {
  .entry-title a {
    color: #e4cbfd !important;
  }
  .fusion-single-line-meta,
  .fusion-single-line-meta a,
  .fusion-post-content-container {
    color: #fff !important;
  }
}
